import React, { FC, useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { TextVariant } from '@naf/text';
import type { AddonProductType } from '@dtp/membership-service-types';
import { GridCol, GridRow } from '@naf/grid';
import { WizardNavigation } from '../../components/WizardNavigation';
import { updateAction } from '../../lib/updateAction';
import useSelector from '../../../../redux/typedHooks';
import { createOrderPayload } from '../../lib/createOrderPayload';
import { Summary } from '../../components/Summary';
import { useSendGTMEventOnce } from '../../../../hooks/GTM/useSendGTMEventOnce';
import { useQueryParams } from '../../../../hooks/useQueryParameters';
import { useAuth0Token } from '../../../../hooks/useAuth0Token';
import { AddonForm } from './Form';
import { AddonsFormType } from '../../../../../../types/addonsFormType';
import { actions as orderActions } from '../../../../redux/modules/order';
import { funnelPayload } from '../../../../../../types/gtmFunnel';
import S from './Styles';

// Define the form - receives props from wizard
export const Addons: FC<Partial<StepWizardChildProps>> = ({
  totalSteps,
  currentStep,
  nextStep,
  previousStep,
  goToStep,
}) => {
  const dispatch = useDispatch();
  const { baseUrl } = useSelector((state) => state.application);
  const { simpleToken } = useAuth0Token();

  const queryParams = useQueryParams();

  // Get current form state from session storage
  const {
    actions,
    state: { membershipForm, membersForm },
  } = useStateMachine({ updateAction });

  const { customerInformation } = useSelector((state) => state.myMembership);

  // Function submitting form data to local storage
  const onSubmit = (data: any) => {
    const copiedData = cloneDeep(data);
    // Save the data in local storage in case the api call errors
    actions.updateAction({ formName: 'addonsForm', data: copiedData });
    if (membersForm.mainMember) {
      dispatch(
        orderActions.createOrder.request(
          createOrderPayload(
            membersForm,
            membershipForm,
            `${baseUrl}/velkommen-som-medlem`,
            queryParams?.campaignCode,
            customerInformation.data.customerId,
            data,
            availableAddonProductsMap,
          ),
          { token: simpleToken },
        ),
      );
    }
  };

  // Function submitting form data to local storage
  const onGoback = (data: AddonsFormType) => {
    const copiedData = cloneDeep(data);
    actions.updateAction({ formName: 'addonsForm', data: copiedData });
  };

  // Initiate a form context
  const {
    register,
    watch,
    control,
    handleSubmit,
    unregister,
    setError,
    clearErrors,
    setValue,
    setFocus,
    trigger,
    formState,
    reset,
    resetField,
    getValues,
    getFieldState,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  // Check if a product has been selected, if not, go back to step 1
  useEffect(() => {
    if (membershipForm && !membershipForm.product && goToStep) {
      goToStep(1);
    }
  }, [membershipForm, goToStep]);

  useSendGTMEventOnce(funnelPayload('bli-medlem', 3), [
    membershipForm?.product?.productId,
    membershipForm?.product?.amount,
  ]);

  const addonForm = watch();

  const availableAddonProducts = useSelector(
    (state) => state.becomeAMember.data.products[membershipForm?.product?.productId]?.availableAddOnProducts?.products,
  );

  const availableAddonProductsMap = availableAddonProducts?.reduce((acc: Record<string, AddonProductType>, cur) => {
    acc[cur.productNumber] = cur;
    return acc;
  }, {});

  const addonProductsDescription = useSelector(
    (state) =>
      state.becomeAMember.data.products[membershipForm?.product?.productId]?.availableAddOnProducts?.description,
  );

  const products = {
    mainProduct: membershipForm?.product,
    addOnProducts: Object.entries(addonForm)
      .filter((e) => e[1].members.length > 0)
      .map(([productId, { members }]) => ({ productId, amount: members.length })),
  };

  return (
    <GridRow>
      <FormProvider
        register={register}
        watch={watch}
        control={control}
        handleSubmit={handleSubmit}
        unregister={unregister}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        setFocus={setFocus}
        trigger={trigger}
        formState={formState}
        getValues={getValues}
        reset={reset}
        resetField={resetField}
        getFieldState={getFieldState}
      >
        <GridCol s="12" m="12" l="7" xl="7">
          <S.FirstHeading variant={TextVariant.Header2}>Tilleggsvalg</S.FirstHeading>
          <AddonForm
            availableAddOnProducts={availableAddonProducts}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            unregister={unregister}
          />
          <S.Description>{addonProductsDescription}</S.Description>
        </GridCol>
        <Summary products={products} />
        <GridCol s="12" m="12" l="12" xl="12">
          <WizardNavigation
            totalSteps={totalSteps}
            currentStep={currentStep}
            nextStep={nextStep}
            previousStep={previousStep}
            isValid={Object.values(errors).length === 0}
            onSubmit={onSubmit}
            onGoback={onGoback}
            getValues={getValues}
          />
        </GridCol>
      </FormProvider>
    </GridRow>
  );
};
